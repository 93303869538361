<!--
 * @Author: 月魂
 * @Date: 2020-12-01 16:46:45
 * @LastEditTime: 2022-01-31 13:36:59
 * @LastEditors: 月魂
 * @Description: 
 * @FilePath: \bingmayong-ukraine-pc\src\views\index.vue
-->
<template>
  <div class="goTop">
    <el-backtop target=".goTop">
      <i class="el-icon-caret-top"></i>
    </el-backtop>
    <img src="http://image.wearetogether.com.cn/poster-ukraine-norm.jpg" width="100%" />
    <router-link to="/preface">
      <img
        src="http://image.wearetogether.com.cn/index-ukraine.png"
        class="btn"
        width="25%"
      />
    </router-link>
    <Footer />
  </div>
</template>

<script>
import Footer from "../components/footer"
export default {
  name: "Index",
  components: {
    Footer,
  },
  data () {
    return {
    }
  },
};
</script>

<style scoped>
.goTop {
  min-width: 1200px;
  height: 100vh;
  overflow-x: hidden;
  position: relative;
}
.btn {
  position: fixed;
  bottom: 2%;
  right: 2%;
  cursor: pointer;
}
</style>